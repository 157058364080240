import { Box, Stack, Text } from '@chakra-ui/react'
import { FiRsDownload, FiRsMusicFile, FiRsPicture, FiRsFileSpreadsheet, FiRsFileVideo, FiRsDocument, NextLink } from '../../'
import type React from 'react'

export interface IDownloadItemProps {
  text: string | JSX.Element
  fileLink?: string
  fileName?: string
  iconIsLeft?: boolean
  colorAccent?: string
  textWhite?: boolean
}

const getFileIcon = (fileType: string | undefined) => {
  if (['pdf', 'doc', 'docx', 'txt'].includes(fileType ?? '')) {
    return <FiRsDocument/>
  }
  if (['xls', 'xlsx', 'csv'].includes(fileType ?? '')) {
    return <FiRsFileSpreadsheet/>
  }
  if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(fileType ?? '')) {
    return <FiRsPicture/>
  }
  if (['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv'].includes(fileType ?? '')) {
    return <FiRsFileVideo/>
  }
  if (['mp3', 'wav', 'ogg', 'flac', 'aac'].includes(fileType ?? '')) {
    return <FiRsMusicFile/>
  }

  return <FiRsDownload/>
}

export const DownloadItem: React.FC<IDownloadItemProps> = ({ text, fileLink, fileName, iconIsLeft = true, colorAccent = 'accent.500', textWhite = false }) => {
  // Here we get the file extension from the filename and also make it lowercase since sometimes file extensions can be uppercase
  const fileType = fileLink?.split('.').pop()?.toLowerCase()

  return (
    <NextLink
      mb={3}
      w="100%"
      _hover={{ textDecoration: 'none', color: 'accent.500' }}
      href={fileLink}
      download={fileName}
      display="flex"
      isExternal
    >
      <Stack
        direction={iconIsLeft ? 'row-reverse' : 'row'}
        align="center"
        justify={iconIsLeft ? 'flex-start' : 'space-between'}
        w="100%"
      >
        <Text
          color={textWhite ? 'white' : ''}
          noOfLines={1}
          w="100%"
        >{text}
        </Text>
        <Box fontSize="22px" lineHeight={0} color={textWhite ? 'white' : colorAccent}>
          {getFileIcon(fileType)}
        </Box>
      </Stack>
    </NextLink>
  )
}
