import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import type { ActiveOrderCartQuery } from '@stocker/codegen/vendure'
import {
  useAddItemToOrderBySkuMutation,
  useApplyCouponCodeMutation,
  useRemoveCouponCodeMutation,
} from '@stocker/codegen/vendure'
import { A11CommerceConfig } from '@stocker/commerce-config'
import type { ICartProductItemProps, IFormData } from '@stocker/ui-components/design-system'
import {
  ButtonData,
  CartCheckout,
  CartProductListing,
  CustomText,
  Form,
  IncrementAmountInputData,
  InputData,
} from '@stocker/ui-components/design-system'
import Link from 'next/link'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { DeleteModal } from '../../modules/DeleteModal/DeleteModal'

export interface ICartPageProps {
  activeOrder: ActiveOrderCartQuery['activeOrder']
  preparedProductData: ICartProductItemProps[]
  note?: string
  buttonProps?: {
    onButtonClick: () => void
    buttonText: string
    isLoading?: boolean
    buttonColor?: string
  }
  alwaysMobile?: boolean
  refetchActiveOrder?: () => void
}

export const CartPageResponsive: React.FC<ICartPageProps> = ({
  activeOrder,
  preparedProductData,
  buttonProps,
  note,
  alwaysMobile,
  refetchActiveOrder,
}) => {
  const intl = useIntl()
  const toast = useToast()

  const { mutate: applyCoupon, isLoading: applyCouponIsLoading } = useApplyCouponCodeMutation({
    onSuccess: async (data) => {
      if ('errorCode' in data.applyCouponCode) {
        return toast({
          title: intl.formatMessage({ id: '--error' }),
          description: data.applyCouponCode.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
      refetchActiveOrder?.()
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  const { mutate: removeCoupon, isLoading: removeCouponIsLoading } = useRemoveCouponCodeMutation({
    onSuccess: async (data) => {
      refetchActiveOrder?.()
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  const { mutate: addItemBySku, isLoading: addItemBySkuIsLoadings } =
    useAddItemToOrderBySkuMutation({
      onSuccess: async (data) => {
        if ('errorCode' in data.addItemToOrderBySku) {
          return toast({
            title: intl.formatMessage({ id: '--error' }),
            description: data.addItemToOrderBySku.message,
            status: 'error',
            duration: 10000,
            isClosable: true,
          })
        }
        refetchActiveOrder?.()
        return toast({
          title: intl.formatMessage({ id: '--success' }),
          description: intl.formatMessage({ id: 'cart--added-by-sku-success' }),
          status: 'success',
          duration: 10000,
          isClosable: true,
        })
      },
      onError: (error) => {
        console.log('error', error)
      },
    })

  const serializedCartForOfferTool = useMemo(() => {
    const cart = activeOrder?.lines.map((line) => {
      return {
        i: line.productVariant.sku,
        q: line.quantity,
      }
    })
    return Buffer.from(JSON.stringify(cart)).toString('base64')
  }, [activeOrder])

  const couponFormValidation = useMemo(
    () =>
      yup.object().shape({
        coupon: yup.string(),
      }),
    [],
  )

  const couponFormData: IFormData = useMemo(
    () => ({
      formTitle: '',
      blocks: [
        [
          {
            fields: [
              [
                new InputData({
                  defaultValue: '',
                  title: intl.formatMessage({ id: '--apply-coupon' }),
                  name: 'coupon',
                  type: 'text',
                  minWidth: '200px',
                }),
                new ButtonData({
                  title: intl.formatMessage({ id: '--apply' }),
                  type: 'submit',
                  inputProps: { isLoading: applyCouponIsLoading, marginTop: '24px' },
                }),
              ],
            ],
          },
        ],
      ],
    }),
    [applyCouponIsLoading, intl],
  )

  const addBySkuFormValidation = useMemo(
    () =>
      yup.object().shape({
        sku: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
        quantity: yup.number().required(intl.formatMessage({ id: 'form--field-is-required' })),
      }),
    [],
  )

  const addBySkuFormData: IFormData = useMemo(
    () => ({
      formTitle: '',
      blocks: [
        [
          {
            fields: [
              [
                new InputData({
                  defaultValue: '',
                  title: intl.formatMessage({ id: '--quick-add-sku' }),
                  name: 'sku',
                  type: 'text',
                  minWidth: '250px',
                  grow: 2,
                }),
                new IncrementAmountInputData({
                  minValue: 1,
                  maxValue: 999,
                  defaultValue: 1,
                  title: intl.formatMessage({ id: '--quantity' }),
                  name: 'quantity',
                  type: 'number',
                  minWidth: '80px',
                }),
                new ButtonData({
                  title: intl.formatMessage({ id: '--add' }),
                  type: 'submit',
                  inputProps: { isLoading: addItemBySkuIsLoadings, marginTop: '24px', w: '100%' },
                }),
              ],
            ],
          },
        ],
      ],
    }),
    [addItemBySkuIsLoadings, intl],
  )

  const singleValues =
    activeOrder?.lines
      .filter((item) => {
        return (
          item.productVariant.options.filter((option) => {
            return option.group.name === 'dn'
          }).length > 0
        )
      })
      .map((item) => {
        return (
          item.productVariant.options.filter((option) => {
            return option.group.name === 'dn'
          })[0] ?? null
        )
      }) ?? []

  if (!activeOrder || activeOrder.lines.length === 0) {
    return (
      <Stack spacing="30px">
        <Heading as="h2">{intl.formatMessage({ id: '--cart' })}</Heading>
        <Text>{intl.formatMessage({ id: 'cart-page--add-something-to-cart' })}</Text>
      </Stack>
    )
  }

  // find unique DN values
  const uniqueDNValues = singleValues.filter((item, index, self) => {
    return self.findIndex((t) => t.code === item.code) === index
  })

  const checkoutDisabled = useMemo(() => {
    const erpId = activeOrder.customer?.customFields?.erpId
    return !erpId || typeof erpId !== 'string' || erpId.trim() === ''
  }, [activeOrder.customer])

  return (
    <Stack spacing="30px">
      <SimpleGrid
        display="flex"
        columns={alwaysMobile ? 1 : { base: 1, sm: 2 }}
        alignContent="center"
        justifyContent="space-between"
      >
        <Stack
          direction={alwaysMobile ? 'column' : { base: 'column', sm: 'row' }}
          justify="space-between"
          w="100%"
        >
          <HStack>
            <Heading as="h2">{intl.formatMessage({ id: '--cart' })}</Heading>
            <Text fontSize="2xl">
              {activeOrder.lines.length > 0 && ` (${String(activeOrder.lines.length)})`}
            </Text>
          </HStack>
          <HStack spacing={4} className="not-visible-when-printing">
            <Button
              onClick={() => {
                window.print()
              }}
            >
              {intl.formatMessage({ id: 'cart-page--print-cart' })}
            </Button>
            <Link href={`/account/offer/createoffer?positionData=${serializedCartForOfferTool}`}>
              <Button>{intl.formatMessage({ id: 'cart-page--cart-to-offer' })}</Button>
            </Link>
            <DeleteModal
              modalProps={{
                deleteButtonProps: {
                  label: intl.formatMessage({ id: '--delete' }),
                  functionOnClick: () => {
                    buttonProps?.onButtonClick()
                    toast({
                      title: intl.formatMessage({ id: '--delete-cart-successfully-header' }),
                      description: intl.formatMessage({ id: '--delete-cart-successfully-text' }),
                      status: 'success',
                    })
                  },
                  isLoading: buttonProps?.isLoading,
                  colorScheme: 'accent',
                },
                modalHeader: intl.formatMessage({ id: '--delete-cart-header' }),
                modalText: intl.formatMessage({ id: '--delete-cart-text' }),
                dontDeleteButtonLabel: intl.formatMessage({ id: '--dont-delete' }),
              }}
              primaryButtonProps={{
                minW: 'fit-content',
                backgroundColor: buttonProps?.buttonColor,
                label: buttonProps?.buttonText ?? '',
                color: 'primaryText.500',
                colorScheme: 'accent',
              }}
            />
          </HStack>
        </Stack>
      </SimpleGrid>
      {/* Warning will show if items with different DN are in the cart. */}
      {uniqueDNValues.length > 1 && (
        <Alert status="warning">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold" fontSize="lg">
              {intl.formatMessage({ id: 'cart--wrong-dimensions-warning' })} DN:{' '}
              {uniqueDNValues.map((v) => v.code).join(', ')}
            </Text>
          </Box>
        </Alert>
      )}
      <CartProductListing articles={preparedProductData} alwaysMobile={alwaysMobile} />
      <Box
        display={{ base: 'initial', lg: 'none' }}
        w={{ base: '100%', lg: '40%' }}
        className="not-visible-when-printing"
      >
        <Form
          blockGap={{ vertical: '1rem', horizontal: '10rem' }}
          fieldGap="1rem"
          items={addBySkuFormData}
          onSubmit={(values) => {
            addItemBySku({ input: { sku: values.sku, quantity: Number(values.quantity) } })
            values.sku = ''
          }}
          validationSchema={addBySkuFormValidation}
        />
      </Box>
      {note && <CustomText fontSize={{ base: 'sm', md: 'md' }} text={note} />}
      {A11CommerceConfig.showPromotionCodeInput && (
        <Box display={{ base: 'initial', lg: 'none' }}>
          <Form
            blockGap={{ vertical: '1rem', horizontal: '10rem' }}
            fieldGap="1rem"
            items={couponFormData}
            onSubmit={(values) => {
              applyCoupon({ couponCode: values.coupon })
              values.coupon = ''
            }}
            validationSchema={couponFormValidation}
          />
        </Box>
      )}
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={6}
        display={alwaysMobile ? 'none' : { base: 'none', lg: 'grid' }}
      >
        {A11CommerceConfig.showPromotionCodeInput && (
          <GridItem>
            <Form
              blockGap={{ vertical: '1rem', horizontal: '10rem' }}
              fieldGap="1rem"
              items={couponFormData}
              onSubmit={(values) => {
                applyCoupon({ couponCode: values.coupon })
                values.coupon = ''
              }}
              validationSchema={couponFormValidation}
            />
          </GridItem>
        )}
        <GridItem colStart={3} colEnd={3}>
          <CartCheckout
            subTotalCost={activeOrder.subTotal}
            shippingCost={activeOrder.shipping}
            totalCost={activeOrder.total}
            totalCostIncludingTax={activeOrder.totalWithTax}
            tax={activeOrder.taxSummary.map((tax) => ({
              total: tax.taxTotal,
              rate: tax.taxRate,
            }))}
            currencyCode={activeOrder.currencyCode}
            promoCodes={activeOrder.promotions.map((promotion, index) => ({
              code: promotion.couponCode ?? '',
              discount:
                activeOrder.discounts.find((discount) => discount.description === promotion.name)
                  ?.amount ?? 0,
              onRemove: () => {
                removeCoupon({ couponCode: promotion.couponCode ?? '' })
              },
            }))}
            buttonProps={{
              isLoading: removeCouponIsLoading,
              disabled: checkoutDisabled,
              link: '/checkout',
              onButtonClick: () => {},
              buttonText: intl.formatMessage({
                id: 'cart-page--go-to-checkout',
              }),
              disabledMessage: intl.formatMessage({ id: 'cart-page--go-to-checkout-disabled' }),
            }}
          />
        </GridItem>
      </Grid>
      <Box display={alwaysMobile ? 'inline' : { base: 'inline', lg: 'none' }}>
        <CartCheckout
          subTotalCost={activeOrder.subTotal}
          shippingCost={activeOrder.shipping}
          totalCost={activeOrder.total}
          totalCostIncludingTax={activeOrder.totalWithTax}
          currencyCode={activeOrder.currencyCode}
          promoCodes={activeOrder.promotions.map((promotion, index) => ({
            code: promotion.couponCode ?? '',
            discount:
              activeOrder.discounts.find((discount) => discount.description === promotion.name)
                ?.amount ?? 0,
            onRemove: () => {
              removeCoupon({ couponCode: promotion.couponCode ?? '' })
            },
          }))}
          buttonProps={{
            isLoading: removeCouponIsLoading,
            onButtonClick: () => {},
            buttonText: intl.formatMessage({ id: 'cart-page--go-to-checkout' }),
            link: '/checkout',
            className: 'not-visible-when-printing',
          }}
        />
      </Box>
    </Stack>
  )
}
