/* eslint-disable */
import * as Types from './generated-types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchPimcore } from './fetcher';

export const ImageAssetFragmentDoc = `
    fragment ImageAsset on asset {
  fullpath(thumbnail: "content")
  data(thumbnail: "base64")
  mimetype
}
    `;
export const DownloadGridFragmentDoc = `
    fragment DownloadGrid on fieldcollection_DownloadGrid {
  title
  wrappedWithContainer
  whiteHeadline
  whiteBar
  backgroundImage {
    ...ImageAsset
  }
  downloadItems {
    ... on asset {
      filename
      fullpath
      filesize
      metadata {
        name
        type
        data
        language
      }
    }
  }
}
    `;
export const FeaturedNewsFragmentDoc = `
    fragment FeaturedNews on fieldcollection_FeaturedNews {
  headline
  whiteHeadline
  whiteBar
  backgroundImage {
    ...ImageAsset
  }
  news {
    ... on object_NewsPage {
      name
      text
      slug
      image {
        ...ImageAsset
      }
      productNewsImage {
        ...ImageAsset
      }
    }
  }
}
    `;
export const EmployeeCardGridFragmentDoc = `
    fragment EmployeeCardGrid on fieldcollection_EmployeeCardGrid {
  headline
  employees {
    ... on object_Staff {
      image {
        fullpath(thumbnail: "content")
        data(thumbnail: "base64")
        mimetype
      }
      name
      position
      phone
      mobile
      email
    }
  }
}
    `;
export const HeroSwiperFragmentDoc = `
    fragment HeroSwiper on fieldcollection_HeroSwiper {
  slides {
    title
    text
    image {
      ...ImageAsset
    }
    linkText
    externalLink
    href {
      ... on object_ContentPage {
        absolutePath
      }
    }
  }
}
    `;
export const ProductLineContainerFragmentDoc = `
    fragment ProductLineContainer on fieldcollection_ProductLineContainer {
  title
  text
  buttonLink {
    ... on object_ContentPage {
      absolutePath
    }
  }
  buttonText
  externalLink
  productLines {
    image {
      ...ImageAsset
    }
    productLine
    title
    text
    link {
      ... on object_ContentPage {
        absolutePath
      }
    }
    externalLink
    linkText
  }
}
    `;
export const NewsletterFragmentDoc = `
    fragment Newsletter on fieldcollection_Newsletter {
  formtype
  headline
  text
}
    `;
export const HeadlineFragmentDoc = `
    fragment Headline on fieldcollection_Headline {
  headlineType
  headline
  textBoxed
}
    `;
export const TextFragmentDoc = `
    fragment Text on fieldcollection_Text {
  text
  textBoxed
}
    `;
export const ImageFragmentDoc = `
    fragment Image on fieldcollection_Image {
  image {
    fullpath(thumbnail: "content")
    data(thumbnail: "base64")
    mimetype
  }
}
    `;
export const TextWithImageFragmentDoc = `
    fragment TextWithImage on fieldcollection_TextWithImage {
  imagePosition
  text
  image {
    ...ImageAsset
  }
}
    `;
export const ButtonFragmentDoc = `
    fragment Button on fieldcollection_Button {
  color
  isExternal
  position
  fullWidth
  link {
    text
    path
  }
}
    `;
export const ScriptFragmentDoc = `
    fragment Script on fieldcollection_Script {
  scriptSrc
  bodyContent
}
    `;
export const ElementsFragmentDoc = `
    fragment elements on object_ContentPage_elements {
  __typename
  ...DownloadGrid
  ...FeaturedNews
  ...EmployeeCardGrid
  ...HeroSwiper
  ...ProductLineContainer
  ...Newsletter
  ...Headline
  ...Text
  ...Image
  ...TextWithImage
  ...Button
  ...Script
}
    `;
export const HotspotImageFragmentDoc = `
    fragment HotspotImage on hotspotimage {
  image {
    mobileImage: fullpath(thumbnail: "content")
    fullpath(thumbnail: "300dpi-full-width")
    data(thumbnail: "base64")
    mimetype
  }
}
    `;
 const ContentDocument = `
    query Content($path: String!, $lang: String!) {
  getContentPageBySlugOrPath(absolutePath: $path, language: $lang) {
    content {
      name
      absolutePath
      parent {
        ... on object_ContentPage {
          name
          absolutePath
          parent {
            ... on object_ContentPage {
              name
              absolutePath
              parent {
                ... on object_ContentPage {
                  name
                  absolutePath
                  parent {
                    ... on object_ContentPage {
                      name
                      absolutePath
                    }
                  }
                }
              }
            }
          }
        }
      }
      seoTitle
      seoDescription
      headerTitle
      headerTitleAccentColor
      headerImage {
        image {
          ...ImageAsset
        }
      }
      canonicals {
        ... on object_ContentPage {
          absolutePath
        }
      }
      elements {
        ...elements
      }
    }
  }
}
    ${ImageAssetFragmentDoc}
${ElementsFragmentDoc}
${DownloadGridFragmentDoc}
${FeaturedNewsFragmentDoc}
${EmployeeCardGridFragmentDoc}
${HeroSwiperFragmentDoc}
${ProductLineContainerFragmentDoc}
${NewsletterFragmentDoc}
${HeadlineFragmentDoc}
${TextFragmentDoc}
${ImageFragmentDoc}
${TextWithImageFragmentDoc}
${ButtonFragmentDoc}
${ScriptFragmentDoc}`;

export const useContentQuery = <
      TData = Types.ContentQuery,
      TError = unknown
    >(
      variables: Types.ContentQueryVariables,
      options?: UseQueryOptions<Types.ContentQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ContentQuery, TError, TData>(
      ['Content', variables],
      fetchPimcore<Types.ContentQuery, Types.ContentQueryVariables>(ContentDocument, variables),
      options
    )};

useContentQuery.getKey = (variables: Types.ContentQueryVariables) => ['Content', variables];


useContentQuery.fetcher = (variables: Types.ContentQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.ContentQuery, Types.ContentQueryVariables>(ContentDocument, variables, options);

 const ContentPagesDocument = `
    query ContentPages($defaultLanguage: String!) {
  getContentPageListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        absolutePath
      }
    }
  }
}
    `;

export const useContentPagesQuery = <
      TData = Types.ContentPagesQuery,
      TError = unknown
    >(
      variables: Types.ContentPagesQueryVariables,
      options?: UseQueryOptions<Types.ContentPagesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ContentPagesQuery, TError, TData>(
      ['ContentPages', variables],
      fetchPimcore<Types.ContentPagesQuery, Types.ContentPagesQueryVariables>(ContentPagesDocument, variables),
      options
    )};

useContentPagesQuery.getKey = (variables: Types.ContentPagesQueryVariables) => ['ContentPages', variables];


useContentPagesQuery.fetcher = (variables: Types.ContentPagesQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.ContentPagesQuery, Types.ContentPagesQueryVariables>(ContentPagesDocument, variables, options);

 const ClassificationSortingDocument = `
    query ClassificationSorting($defaultLanguage: String) {
  getWebsiteConfigurationListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        sortingClassification {
          ... on object_ProductClassification {
            name
          }
        }
      }
    }
  }
}
    `;

export const useClassificationSortingQuery = <
      TData = Types.ClassificationSortingQuery,
      TError = unknown
    >(
      variables?: Types.ClassificationSortingQueryVariables,
      options?: UseQueryOptions<Types.ClassificationSortingQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ClassificationSortingQuery, TError, TData>(
      variables === undefined ? ['ClassificationSorting'] : ['ClassificationSorting', variables],
      fetchPimcore<Types.ClassificationSortingQuery, Types.ClassificationSortingQueryVariables>(ClassificationSortingDocument, variables),
      options
    )};

useClassificationSortingQuery.getKey = (variables?: Types.ClassificationSortingQueryVariables) => variables === undefined ? ['ClassificationSorting'] : ['ClassificationSorting', variables];


useClassificationSortingQuery.fetcher = (variables?: Types.ClassificationSortingQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.ClassificationSortingQuery, Types.ClassificationSortingQueryVariables>(ClassificationSortingDocument, variables, options);

 const StaffListingDocument = `
    query StaffListing($shortCodeFilter: String!) {
  getStaffListing(filter: $shortCodeFilter, first: 1) {
    edges {
      node {
        image {
          fullpath
        }
        shortCode
        name
        position
        phone
        mobile
        email
      }
    }
  }
}
    `;

export const useStaffListingQuery = <
      TData = Types.StaffListingQuery,
      TError = unknown
    >(
      variables: Types.StaffListingQueryVariables,
      options?: UseQueryOptions<Types.StaffListingQuery, TError, TData>
    ) => {
    
    return useQuery<Types.StaffListingQuery, TError, TData>(
      ['StaffListing', variables],
      fetchPimcore<Types.StaffListingQuery, Types.StaffListingQueryVariables>(StaffListingDocument, variables),
      options
    )};

useStaffListingQuery.getKey = (variables: Types.StaffListingQueryVariables) => ['StaffListing', variables];


useStaffListingQuery.fetcher = (variables: Types.StaffListingQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.StaffListingQuery, Types.StaffListingQueryVariables>(StaffListingDocument, variables, options);

 const SystemFinderListingDocument = `
    query SystemFinderListing {
  getSystemFinderListing {
    edges {
      node {
        introText
        introHeadline
        introImage {
          fullpath(thumbnail: "content")
        }
        projectHeadline
        projectText
        project {
          projectFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        operatingModeHeadline
        operatingModeText
        operatingMode {
          operatingModeFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        doubleWalledPressureHeadline
        doubleWalledPressureText
        doubleWalledPressure {
          doubleWalledPressureFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        pressureHeadline
        pressureText
        pressure {
          pressureFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        roomAirHeadline
        roomAirText
        roomAir {
          roomAirFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        executionHeadline
        executionText
        execution {
          executionFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
      }
    }
  }
}
    `;

export const useSystemFinderListingQuery = <
      TData = Types.SystemFinderListingQuery,
      TError = unknown
    >(
      variables?: Types.SystemFinderListingQueryVariables,
      options?: UseQueryOptions<Types.SystemFinderListingQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SystemFinderListingQuery, TError, TData>(
      variables === undefined ? ['SystemFinderListing'] : ['SystemFinderListing', variables],
      fetchPimcore<Types.SystemFinderListingQuery, Types.SystemFinderListingQueryVariables>(SystemFinderListingDocument, variables),
      options
    )};

useSystemFinderListingQuery.getKey = (variables?: Types.SystemFinderListingQueryVariables) => variables === undefined ? ['SystemFinderListing'] : ['SystemFinderListing', variables];


useSystemFinderListingQuery.fetcher = (variables?: Types.SystemFinderListingQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.SystemFinderListingQuery, Types.SystemFinderListingQueryVariables>(SystemFinderListingDocument, variables, options);

 const NavigationByIdentifierDocument = `
    query NavigationByIdentifier($identifier: String!, $lang: String!) {
  navigationByIdentifier(identifier: $identifier, language: $lang) {
    linkItems {
      title
      absolutePath
      additionalData
      nameInNavigation
      slug
      className
      linkItems {
        title
        slug
        absolutePath
        additionalData
        nameInNavigation
        className
        linkItems {
          title
          additionalData
          nameInNavigation
          slug
          absolutePath
          className
          linkItems {
            title
            additionalData
            nameInNavigation
            slug
            absolutePath
            className
          }
        }
      }
    }
  }
}
    `;

export const useNavigationByIdentifierQuery = <
      TData = Types.NavigationByIdentifierQuery,
      TError = unknown
    >(
      variables: Types.NavigationByIdentifierQueryVariables,
      options?: UseQueryOptions<Types.NavigationByIdentifierQuery, TError, TData>
    ) => {
    
    return useQuery<Types.NavigationByIdentifierQuery, TError, TData>(
      ['NavigationByIdentifier', variables],
      fetchPimcore<Types.NavigationByIdentifierQuery, Types.NavigationByIdentifierQueryVariables>(NavigationByIdentifierDocument, variables),
      options
    )};

useNavigationByIdentifierQuery.getKey = (variables: Types.NavigationByIdentifierQueryVariables) => ['NavigationByIdentifier', variables];


useNavigationByIdentifierQuery.fetcher = (variables: Types.NavigationByIdentifierQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.NavigationByIdentifierQuery, Types.NavigationByIdentifierQueryVariables>(NavigationByIdentifierDocument, variables, options);

 const NewsDocument = `
    query News($path: String!, $lang: String!) {
  getNewsItemBySlugOrPath(absolutePath: $path, language: $lang) {
    newsItem {
      seoTitle
      seoDescription
      headerTitle
      headerTitleAccentColor
      headerImage {
        ...HotspotImage
      }
      canonicals {
        ... on object_ContentPage {
          absolutePath
        }
      }
      elements {
        __typename
        ...Text
        ...Headline
        ...Image
        ...TextWithImage
      }
      name
    }
  }
}
    ${HotspotImageFragmentDoc}
${TextFragmentDoc}
${HeadlineFragmentDoc}
${ImageFragmentDoc}
${TextWithImageFragmentDoc}
${ImageAssetFragmentDoc}`;

export const useNewsQuery = <
      TData = Types.NewsQuery,
      TError = unknown
    >(
      variables: Types.NewsQueryVariables,
      options?: UseQueryOptions<Types.NewsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.NewsQuery, TError, TData>(
      ['News', variables],
      fetchPimcore<Types.NewsQuery, Types.NewsQueryVariables>(NewsDocument, variables),
      options
    )};

useNewsQuery.getKey = (variables: Types.NewsQueryVariables) => ['News', variables];


useNewsQuery.fetcher = (variables: Types.NewsQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.NewsQuery, Types.NewsQueryVariables>(NewsDocument, variables, options);

 const LatestNewsDocument = `
    query LatestNews($first: Int = 5, $after: Int = 0, $lang: String!) {
  getNewsPageListing(
    sortBy: "displayDate"
    sortOrder: "DESC"
    first: $first
    after: $after
    defaultLanguage: $lang
  ) {
    totalCount
    edges {
      node {
        displayDate
        absolutePath
        name
        text
        image {
          ...ImageAsset
        }
      }
    }
  }
}
    ${ImageAssetFragmentDoc}`;

export const useLatestNewsQuery = <
      TData = Types.LatestNewsQuery,
      TError = unknown
    >(
      variables: Types.LatestNewsQueryVariables,
      options?: UseQueryOptions<Types.LatestNewsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.LatestNewsQuery, TError, TData>(
      ['LatestNews', variables],
      fetchPimcore<Types.LatestNewsQuery, Types.LatestNewsQueryVariables>(LatestNewsDocument, variables),
      options
    )};

useLatestNewsQuery.getKey = (variables: Types.LatestNewsQueryVariables) => ['LatestNews', variables];


useLatestNewsQuery.fetcher = (variables: Types.LatestNewsQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.LatestNewsQuery, Types.LatestNewsQueryVariables>(LatestNewsDocument, variables, options);

 const NewsPagesDocument = `
    query NewsPages($defaultLanguage: String!) {
  getNewsPageListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        absolutePath
      }
    }
  }
}
    `;

export const useNewsPagesQuery = <
      TData = Types.NewsPagesQuery,
      TError = unknown
    >(
      variables: Types.NewsPagesQueryVariables,
      options?: UseQueryOptions<Types.NewsPagesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.NewsPagesQuery, TError, TData>(
      ['NewsPages', variables],
      fetchPimcore<Types.NewsPagesQuery, Types.NewsPagesQueryVariables>(NewsPagesDocument, variables),
      options
    )};

useNewsPagesQuery.getKey = (variables: Types.NewsPagesQueryVariables) => ['NewsPages', variables];


useNewsPagesQuery.fetcher = (variables: Types.NewsPagesQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.NewsPagesQuery, Types.NewsPagesQueryVariables>(NewsPagesDocument, variables, options);

 const ProductCategoryListingDocument = `
    query ProductCategoryListing($filter: String!) {
  getProductCategoryListing(filter: $filter, defaultLanguage: "de", first: 1) {
    edges {
      node {
        subheadline
        coloredName
        description
        infoImage {
          fullpath(thumbnail: "content")
          filename
          dimensions {
            width
            height
          }
        }
        onSiteImages {
          image {
            filename
            fullpath(thumbnail: "content")
          }
        }
        systemInformationItems {
          itemText
          itemHeadline
        }
        relatedProduct {
          ... on object_Product {
            slug
          }
        }
        downloads {
          ... on asset {
            filename
            fullpath
            metadata {
              name
              data
              language
            }
          }
        }
      }
    }
  }
}
    `;

export const useProductCategoryListingQuery = <
      TData = Types.ProductCategoryListingQuery,
      TError = unknown
    >(
      variables: Types.ProductCategoryListingQueryVariables,
      options?: UseQueryOptions<Types.ProductCategoryListingQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ProductCategoryListingQuery, TError, TData>(
      ['ProductCategoryListing', variables],
      fetchPimcore<Types.ProductCategoryListingQuery, Types.ProductCategoryListingQueryVariables>(ProductCategoryListingDocument, variables),
      options
    )};

useProductCategoryListingQuery.getKey = (variables: Types.ProductCategoryListingQueryVariables) => ['ProductCategoryListing', variables];


useProductCategoryListingQuery.fetcher = (variables: Types.ProductCategoryListingQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.ProductCategoryListingQuery, Types.ProductCategoryListingQueryVariables>(ProductCategoryListingDocument, variables, options);

 const ProductCategoryListingSystemFinderDocument = `
    query ProductCategoryListingSystemFinder($filter: String!) {
  getProductCategoryListing(filter: $filter, defaultLanguage: "de") {
    edges {
      node {
        isSystem
        slug
        description
        text
        coloredName
        id
        infoImage {
          fullpath(thumbnail: "content")
        }
        relatedProduct {
          ... on object_Product {
            slug
          }
        }
        diameter
        wallThickness
        material
        maxExhaustTemperature
        systemFinderFilters {
          __typename
          ... on object_SystemFinderFilter {
            id
          }
        }
      }
    }
  }
}
    `;

export const useProductCategoryListingSystemFinderQuery = <
      TData = Types.ProductCategoryListingSystemFinderQuery,
      TError = unknown
    >(
      variables: Types.ProductCategoryListingSystemFinderQueryVariables,
      options?: UseQueryOptions<Types.ProductCategoryListingSystemFinderQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ProductCategoryListingSystemFinderQuery, TError, TData>(
      ['ProductCategoryListingSystemFinder', variables],
      fetchPimcore<Types.ProductCategoryListingSystemFinderQuery, Types.ProductCategoryListingSystemFinderQueryVariables>(ProductCategoryListingSystemFinderDocument, variables),
      options
    )};

useProductCategoryListingSystemFinderQuery.getKey = (variables: Types.ProductCategoryListingSystemFinderQueryVariables) => ['ProductCategoryListingSystemFinder', variables];


useProductCategoryListingSystemFinderQuery.fetcher = (variables: Types.ProductCategoryListingSystemFinderQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.ProductCategoryListingSystemFinderQuery, Types.ProductCategoryListingSystemFinderQueryVariables>(ProductCategoryListingSystemFinderDocument, variables, options);

 const SiteConfigListingDocument = `
    query SiteConfigListing($language: String!) {
  getSiteConfigListing(defaultLanguage: $language) {
    edges {
      node {
        customerDownloads {
          __typename
          ... on asset {
            id
            filename
            fullpath
            type
            filesize
            metadata {
              name
              data
              language
            }
          }
        }
      }
    }
  }
}
    `;

export const useSiteConfigListingQuery = <
      TData = Types.SiteConfigListingQuery,
      TError = unknown
    >(
      variables: Types.SiteConfigListingQueryVariables,
      options?: UseQueryOptions<Types.SiteConfigListingQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SiteConfigListingQuery, TError, TData>(
      ['SiteConfigListing', variables],
      fetchPimcore<Types.SiteConfigListingQuery, Types.SiteConfigListingQueryVariables>(SiteConfigListingDocument, variables),
      options
    )};

useSiteConfigListingQuery.getKey = (variables: Types.SiteConfigListingQueryVariables) => ['SiteConfigListing', variables];


useSiteConfigListingQuery.fetcher = (variables: Types.SiteConfigListingQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.SiteConfigListingQuery, Types.SiteConfigListingQueryVariables>(SiteConfigListingDocument, variables, options);

 const WebsiteConfigurationDocument = `
    query WebsiteConfiguration($id: Int, $language: String) {
  getWebsiteConfiguration(id: $id, defaultLanguage: $language) {
    newsletter
    links {
      headline
      links {
        ... on object_ContentPage {
          name
          absolutePath
        }
      }
    }
    contact
  }
}
    `;

export const useWebsiteConfigurationQuery = <
      TData = Types.WebsiteConfigurationQuery,
      TError = unknown
    >(
      variables?: Types.WebsiteConfigurationQueryVariables,
      options?: UseQueryOptions<Types.WebsiteConfigurationQuery, TError, TData>
    ) => {
    
    return useQuery<Types.WebsiteConfigurationQuery, TError, TData>(
      variables === undefined ? ['WebsiteConfiguration'] : ['WebsiteConfiguration', variables],
      fetchPimcore<Types.WebsiteConfigurationQuery, Types.WebsiteConfigurationQueryVariables>(WebsiteConfigurationDocument, variables),
      options
    )};

useWebsiteConfigurationQuery.getKey = (variables?: Types.WebsiteConfigurationQueryVariables) => variables === undefined ? ['WebsiteConfiguration'] : ['WebsiteConfiguration', variables];


useWebsiteConfigurationQuery.fetcher = (variables?: Types.WebsiteConfigurationQueryVariables, options?: RequestInit['headers']) => fetchPimcore<Types.WebsiteConfigurationQuery, Types.WebsiteConfigurationQueryVariables>(WebsiteConfigurationDocument, variables, options);
