import type { InputProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import type {
  CreateOfferPositionInput,
  CustomerSpecificPriceQuery,
  OfferPriceQuery,
} from '@stocker/codegen/vendure'
import {
  useActiveChannelQuery,
  useCustomerSpecificPriceQuery,
  useOfferPriceQuery,
  useSearchQuery,
} from '@stocker/codegen/vendure'
import { Autocomplete, FiRsTrash } from '@stocker/ui-components/design-system'
import { PriceType, useDebounce, usePriceType } from '@stocker/ui-components/helpers'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'

export interface OfferPositionHandle {
  getOfferPositionData: () => OfferPositionData[]
}

export interface OptionsGroup {
  name: string
}

export interface Options {
  id: number | string
  name: string
  code: string
  group: OptionsGroup
}

export type OfferPositionData = CreateOfferPositionInput & {
  id: number
  price: number
  sku?: string
  endPrice: number
  options?: Options[]
}

interface IOfferEditorProductListProps {
  prefillData?: OfferPositionData[]
  onChange?: (data: OfferPositionData[]) => void
}

export const OfferEditorProductList = React.forwardRef<
  OfferPositionHandle,
  IOfferEditorProductListProps
>(({ prefillData, onChange }, ref) => {
  const { formatMessage } = useIntl()
  const [searchInput, setSearchInput] = useState('')
  const [offerPositionData, setOfferPositionData] = useState<OfferPositionData[]>(prefillData ?? [])
  const debouncedSearchInput = useDebounce(searchInput, 500)
  const [newOfferData, setNewOfferData] = useState<OfferPositionData>({
    id: offerPositionData.length,
    alternative: false,
    discount: 0,
    name: '',
    price: 0,
    endPrice: 0,
    productVariantId: '',
    quantity: 1,
    taxRate: 20,
    positionTotal: 0,
    sku: '',
    options: [],
  })

  const { priceType } = usePriceType()
  const { data: activeChannel } = useActiveChannelQuery()

  const { data, refetch } = useSearchQuery({ input: debouncedSearchInput }, { enabled: false })
  // GETTER FOR PARENT COMPONENT
  const getOfferPositionData = useCallback(() => {
    return offerPositionData
  }, [offerPositionData])

  useImperativeHandle(ref, () => ({
    getOfferPositionData,
  }))

  const handleOfferPositionDataUpdate = (data: OfferPositionData | undefined) => {
    if (data) {
      data.taxRate = !isNaN(data.taxRate) ? data.taxRate : 0
      data.discount = !isNaN(data.discount) ? data.discount : 0
      data.quantity = !isNaN(data.quantity) ? data.quantity : 1
      data.price = !isNaN(data.price) ? data.price : 0
      data.endPrice = !isNaN(data.endPrice) ? data.endPrice : 0
    }

    setOfferPositionData((prev) => {
      if (data) {
        const index = prev.findIndex((item) => item.id === data.id)
        if (index !== -1) {
          prev[index] = recalculatePositionTotal(data)
        }
      }
      return [...prev]
    })
  }

  useEffect(() => {
    refetch()
  }, [debouncedSearchInput])

  // update parent component on mount or change of offerPositionData to display correct vat data
  useLayoutEffect(() => {
    onChange?.(offerPositionData)
  }, [offerPositionData])

  const recalculatePositionTotal = useCallback((data: OfferPositionData) => {
    const totalPrice = data.quantity * data.price
    const discount = (totalPrice * data.discount) / 100
    const tax = ((totalPrice - discount) * data.taxRate) / 100
    const positionTotal = Math.round(tax + totalPrice - discount)
    return { ...data, positionTotal }
  }, [])

  useEffect(() => {
    setNewOfferData((prev) => {
      // calculate position total:
      const totalPrice = prev.quantity * prev.price
      const discount = (totalPrice * prev.discount) / 100
      const tax = ((totalPrice - discount) * prev.taxRate) / 100
      const positionTotal = tax + totalPrice - discount
      return { ...prev, positionTotal }
    })
  }, [newOfferData.quantity, newOfferData.price, newOfferData.discount, newOfferData.taxRate])

  const onSyncDiscountButtonClick = () => {
    const discountToSync = offerPositionData[0].discount
    offerPositionData.map((position) => {
      handleOfferPositionDataUpdate({ ...position, discount: discountToSync })
    })
  }

  const onSyncTaxButtonClick = () => {
    const taxToSync = offerPositionData[0].taxRate
    offerPositionData.map((position) => {
      handleOfferPositionDataUpdate({ ...position, taxRate: taxToSync })
    })
  }

  const getOfferPrice = (
    customPriceData: OfferPriceQuery,
    customerSpecificPrice: CustomerSpecificPriceQuery,
    failSafePrice: number,
    currentPrice?: number,
  ) => {
    if (
      currentPrice &&
      currentPrice !== customPriceData.offerPrices?.items[0]?.price &&
      currentPrice !== customerSpecificPrice.customerSpecificPrice?.basePrice &&
      currentPrice !== customerSpecificPrice.customerSpecificPrice?.price
    ) {
      return currentPrice
    }

    // if custom set price for the product exists use it
    if (customPriceData.offerPrices?.items[0]?.price)
      return customPriceData.offerPrices.items[0]?.price

    // if customerSpecificPrice exists use it depending on the currently selected priceType
    if (customerSpecificPrice.customerSpecificPrice) {
      if (priceType === PriceType.Base) {
        return customerSpecificPrice.customerSpecificPrice.basePrice
      } else {
        return customerSpecificPrice.customerSpecificPrice.price
      }
    }

    // if none of the above apply use the failSafe value
    return failSafePrice
  }

  useEffect(() => {
    offerPositionData.map(async (position) => {
      if (!position.productVariantId) {
        return
      }
      const customPriceData = await useOfferPriceQuery.fetcher({
        productVariantId: position.productVariantId,
      })()

      const customerSpecificPrice = await useCustomerSpecificPriceQuery.fetcher({
        variantId: position.productVariantId,
        channelCode: activeChannel?.activeChannel.code ?? '__default_channel__',
      })()

      handleOfferPositionDataUpdate({
        ...position,
        price: getOfferPrice(
          customPriceData,
          customerSpecificPrice,
          position.price,
          position.price,
        ),
      })
    })
  }, [priceType])

  return (
    <Box>
      <Table mt="0" overflowX="unset">
        <Thead>
          <Tr>
            <Th minW="70px" maxW="80px" whiteSpace="pre-line" pl={0} pr={0}>
              {formatMessage({ id: 'offer-editor--product-list-alternative' })}
            </Th>
            <Th w="100%" pr={0}>
              {formatMessage({ id: 'offer-editor--product-list-position' })}
            </Th>
            <Th minW="80px" pr={0}>
              {formatMessage({ id: 'offer-editor--product-list-quantity' })}
            </Th>
            <Th minW="120px" pr={0}>
              {formatMessage({ id: 'offer-editor--product-list-unit-price' })}
            </Th>
            <Th pr={0}>{formatMessage({ id: 'offer-editor--product-list-vat' })}</Th>
            <Th pr={0}>{formatMessage({ id: 'offer-editor--product-list-discount' })}</Th>
            <Th minW="110px" pr={0} isNumeric>
              {formatMessage({ id: 'offer-editor--product-list-amount' })}
            </Th>
            <Th pr={0}>{formatMessage({ id: 'offer-editor--product-list-delete' })}</Th>
          </Tr>
        </Thead>
        {/* TABLE ROW VALUES THAT ARE ALREADY ADDED */}
        <Tbody>
          {offerPositionData.map((position, index) => (
            <Tr key={`${String(position.productVariantId)}-${index}-${position.id}`}>
              <Td pl={0}>
                <Checkbox
                  isChecked={position.alternative}
                  onChange={(e) => {
                    handleOfferPositionDataUpdate({ ...position, alternative: e.target.checked })
                  }}
                />
              </Td>
              <Td pr={0}>
                <Autocomplete
                  items={
                    data?.search.items.map((item) => ({
                      label: item.productName || item.productVariantName,
                      value: item.productVariantId,
                      sku: item.sku,
                      systemLine: item.customMappings.systemLine ?? '',
                      systemName: item.customMappings.systemName ?? '',
                    })) ?? []
                  }
                  onSelect={async (item) => {
                    const variant = data?.search.items.find(
                      (searchItem) => searchItem.productVariantId === item,
                    )
                    const customPriceData = await useOfferPriceQuery.fetcher({
                      productVariantId: variant?.productVariantId ?? '0',
                    })()

                    const customerSpecificPrice = await useCustomerSpecificPriceQuery.fetcher({
                      variantId: variant?.productVariantId ?? '0',
                      channelCode: activeChannel?.activeChannel.code ?? '__default_channel__',
                    })()

                    handleOfferPositionDataUpdate({
                      ...position,
                      productVariantId: item,
                      endPrice: (
                        variant?.price as { __typename?: 'SinglePrice' | undefined; value: number }
                      ).value,
                      name: (
                        variant?.customMappings.systemName + ' ' + variant?.productName ??
                        variant?.productVariantName ??
                        ''
                      ).trim(),
                      price: getOfferPrice(
                        customPriceData,
                        customerSpecificPrice,
                        (
                          variant?.price as {
                            __typename?: 'SinglePrice' | undefined
                            value: number
                          }
                        ).value,
                      ),
                      sku: variant?.sku,
                    })
                  }}
                  inputValue={position.name.replaceAll('_', '')}
                  onInputFocus={() => {
                    setSearchInput(position.name)
                    refetch()
                  }}
                  inputProps={{
                    variant: 'flushed',
                    placeholder: formatMessage({ id: 'offer-editor--product-list-search' }),
                  }}
                  onChange={(value) => {
                    setSearchInput(value)
                    handleOfferPositionDataUpdate({ ...position, name: value })
                  }}
                />
                {position.sku && (
                  <Text mt="2" size="sm">
                    {formatMessage({ id: 'account--offer-article-sku' })} {position.sku}
                  </Text>
                )}
              </Td>
              <Td pr={0}>
                <Input
                  borderRadius={0}
                  focusBorderColor="accent.500"
                  min="1"
                  variant="flushed"
                  value={position.quantity}
                  placeholder="1"
                  w="50px"
                  onChange={(e) => {
                    handleOfferPositionDataUpdate({ ...position, quantity: Number(e.target.value) })
                  }}
                />
              </Td>
              <Td pr={0}>
                <NumericFormat
                  value={position.price / 100}
                  customInput={MaskedInputFlushed}
                  allowedDecimalSeparators={[',']}
                  decimalSeparator=","
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator="."
                  thousandsGroupStyle="thousand"
                  onValueChange={(e) => {
                    handleOfferPositionDataUpdate({ ...position, price: (e.floatValue ?? 0) * 100 })
                  }}
                />
              </Td>
              <Td pr={0}>
                <InputGroup variant="flushed">
                  <Input
                    borderRadius={0}
                    focusBorderColor="accent.500"
                    value={position.taxRate}
                    placeholder="20"
                    w="35px"
                    onChange={(e) => {
                      handleOfferPositionDataUpdate({
                        ...position,
                        taxRate: parseInt(e.target.value || '0'),
                      })
                    }}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Td>
              <Td pr={0}>
                <InputGroup variant="flushed">
                  <Input
                    borderRadius={0}
                    value={position.discount}
                    focusBorderColor="accent.500"
                    placeholder="0"
                    maxW="60px"
                    onChange={(e) => {
                      handleOfferPositionDataUpdate({
                        ...position,
                        discount: parseInt(e.target.value || '0'),
                      })
                    }}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Td>
              <Td pr={0} isNumeric>
                <FormattedNumber
                  value={position.positionTotal / 100}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Td>
              <Td
                pr={0}
                cursor="pointer"
                onClick={() => {
                  offerPositionData.splice(index, 1)
                  // here we just pseudo update data so the table is rerendered
                  handleOfferPositionDataUpdate(offerPositionData[0])
                }}
              >
                <FiRsTrash />
              </Td>
            </Tr>
          ))}
        </Tbody>
        {/* ADD POSITION ROW */}
        <Tfoot>
          <Tr>
            <Td pl={0}>
              <Checkbox
                isChecked={newOfferData.alternative}
                onChange={(e) => {
                  setNewOfferData((prev) => ({ ...prev, alternative: e.target.checked }))
                }}
              />
            </Td>
            <Td pr={0}>
              <Autocomplete
                items={
                  data?.search.items.map((item) => ({
                    label: item.productName || item.productVariantName,
                    value: item.productVariantId,
                    sku: item.sku,
                    systemLine: item.customMappings.systemLine ?? '',
                    systemName: item.customMappings.systemName ?? '',
                  })) ?? []
                }
                onSelect={async (item) => {
                  const variant = data?.search.items.find(
                    (searchItem) => searchItem.productVariantId === item,
                  )

                  setSearchInput(variant?.productName ?? variant?.productVariantName ?? '')
                  const customPriceData = await useOfferPriceQuery.fetcher({
                    productVariantId: variant?.productVariantId ?? '0',
                  })()

                  const customerSpecificPrice = await useCustomerSpecificPriceQuery.fetcher({
                    variantId: variant?.productVariantId ?? '0',
                    channelCode: activeChannel?.activeChannel.code ?? '__default_channel__',
                  })()

                  setNewOfferData((prev) => {
                    return {
                      ...prev,
                      productVariantId: item,
                      endPrice: (
                        variant?.price as { __typename?: 'SinglePrice' | undefined; value: number }
                      ).value,
                      name: variant?.productName ?? variant?.productVariantName ?? '',
                      price: getOfferPrice(
                        customPriceData,
                        customerSpecificPrice,
                        (
                          variant?.price as {
                            __typename?: 'SinglePrice' | undefined
                            value: number
                          }
                        ).value,
                      ),
                      sku: variant?.sku,
                    }
                  })
                  setOfferPositionData((prev) => [
                    ...prev,
                    recalculatePositionTotal({
                      ...newOfferData,
                      productVariantId: item,
                      endPrice: (
                        variant?.price as { __typename?: 'SinglePrice' | undefined; value: number }
                      ).value,
                      name: variant?.productName ?? variant?.productVariantName ?? '',
                      price: getOfferPrice(
                        customPriceData,
                        customerSpecificPrice,
                        (
                          variant?.price as {
                            __typename?: 'SinglePrice' | undefined
                            value: number
                          }
                        ).value,
                      ),
                      sku: variant?.sku,
                    }),
                  ])

                  setSearchInput('')
                  setNewOfferData({
                    id: offerPositionData.length + 1,
                    alternative: false,
                    discount: 0,
                    name: '',
                    endPrice: 0,
                    price: 0,
                    productVariantId: '',
                    quantity: 1,
                    taxRate: 20,
                    positionTotal: 0,
                    sku: '',
                    options: [],
                  })
                }}
                inputValue={newOfferData.name}
                inputProps={{
                  variant: 'flushed',
                  placeholder: formatMessage({ id: 'offer-editor--product-list-search' }),
                }}
                onChange={(value) => {
                  setNewOfferData((prev) => ({ ...prev, name: value }))
                  setSearchInput(value)
                }}
                onBlur={(value) => {
                  if (!value) return
                  setSearchInput(value)

                  setNewOfferData((prev) => {
                    return {
                      ...prev,
                      productVariantId: '',
                      name: value,
                    }
                  })
                  setOfferPositionData((prev) => [
                    ...prev,
                    recalculatePositionTotal({
                      ...newOfferData,
                      productVariantId: '',
                      name: value,
                    }),
                  ])
                  setSearchInput('')
                  setNewOfferData({
                    id: offerPositionData.length + 1,
                    alternative: false,
                    discount: 0,
                    name: '',
                    endPrice: 0,
                    price: 0,
                    productVariantId: '',
                    quantity: 1,
                    taxRate: 20,
                    positionTotal: 0,
                    options: [],
                  })
                }}
              />
            </Td>
            <Td pr={0}>
              <Input
                focusBorderColor="accent.500"
                variant="flushed"
                min="1"
                value={newOfferData.quantity}
                placeholder="1"
                maxW="40px"
                onChange={(e) => {
                  setNewOfferData((prev) => ({ ...prev, quantity: Number(e.target.value) }))
                }}
              />
            </Td>
            <Td pr={0}>
              <NumericFormat
                value={newOfferData.price / 100}
                customInput={MaskedInputFlushed}
                allowedDecimalSeparators={[',']}
                decimalSeparator=","
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator="."
                thousandsGroupStyle="thousand"
                onValueChange={(e) => {
                  setNewOfferData((prev) => ({ ...prev, price: (e.floatValue ?? 0) * 100 }))
                }}
              />
            </Td>
            <Td pr={0}>
              <InputGroup variant="flushed">
                <Input
                  focusBorderColor="accent.500"
                  value={newOfferData.taxRate}
                  placeholder="20"
                  onChange={(e) => {
                    setNewOfferData((prev) => ({
                      ...prev,
                      taxRate: parseInt(e.target.value || '0'),
                    }))
                  }}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
            </Td>
            <Td pr={0}>
              <InputGroup variant="flushed">
                <Input
                  focusBorderColor="accent.500"
                  value={newOfferData.discount}
                  placeholder="0"
                  onChange={(e) => {
                    setNewOfferData((prev) => ({
                      ...prev,
                      discount: parseInt(e.target.value || '0'),
                    }))
                  }}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
            </Td>
            <Td pr={0} isNumeric>
              <FormattedNumber
                value={newOfferData.positionTotal / 100}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Td>
            <Td />
          </Tr>
        </Tfoot>
      </Table>
      {offerPositionData.length >= 2 && (
        <HStack>
          <Tooltip hasArrow label={formatMessage({ id: 'offer-editor--sync-taxes-tooltip' })}>
            <Button onClick={onSyncTaxButtonClick}>
              {formatMessage({ id: 'offer-editor--sync-taxes' })}
            </Button>
          </Tooltip>
          <Tooltip hasArrow label={formatMessage({ id: 'offer-editor--sync-discount-tooltip' })}>
            <Button onClick={onSyncDiscountButtonClick}>
              {formatMessage({ id: 'offer-editor--sync-discount' })}
            </Button>
          </Tooltip>
        </HStack>
      )}
    </Box>
  )
})

export const MaskedInputFlushed: React.FC = (inputProps: InputProps) => {
  return (
    <InputGroup variant="flushed">
      <Input focusBorderColor="accent.500" placeholder="0" w="90px" {...inputProps} />
      <InputRightAddon>€</InputRightAddon>
    </InputGroup>
  )
}
