import { Box, Button, Stack, Text, Tooltip } from '@chakra-ui/react'
import { showShippingCost } from '@stocker/ui-components/helpers'
import type React from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { FiRsCross, FiRsInfo, NextLink } from '../../'

export interface ICartCheckoutProps {
  subTotalCost: number
  shippingCost: number
  totalCost: number
  totalCostIncludingTax?: number
  tax?: Array<{
    total: number
    rate: number
  }>
  currencyCode?: string
  promoCodes?: Array<{
    code: string
    discount: number
    onRemove?: () => void
  }>
  shippingInfoText?: string
  buttonProps: {
    link?: string
    onButtonClick?: () => void
    buttonText: string
    isLoading?: boolean
    className?: string
    disabled?: boolean
    disabledMessage?: string
  }
}

export const CartCheckout: React.FC<ICartCheckoutProps> = ({
  tax,
  totalCostIncludingTax,
  currencyCode = 'eur',
  shippingInfoText,
  subTotalCost,
  shippingCost,
  totalCost,
  promoCodes,
  buttonProps,
}) => {
  const intl = useIntl()
  return (
    <Box fontSize="lg">
      {showShippingCost(shippingCost) && (
        <>
          <Stack direction="row" justify="space-between">
            <Text>{intl.formatMessage({ id: '--subtotal' })}</Text>
            <Text fontFamily="primary">
              <FormattedNumber
                value={subTotalCost / 100}
                style="currency"
                currency={currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          </Stack>
          <Stack pb="10px" direction="row" justify="space-between">
            <Stack direction="row">
              <Text>{intl.formatMessage({ id: '--delivery-costs' })}</Text>
              {shippingInfoText && (
                <Tooltip
                  label={shippingInfoText}
                  textColor="inherit"
                  bg="white"
                  fontSize="md"
                  hasArrow
                  placement="right"
                >
                  <span>
                    <FiRsInfo />
                  </span>
                </Tooltip>
              )}
            </Stack>
            <Text>
              <FormattedNumber
                value={shippingCost / 100}
                style="currency"
                currency={currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          </Stack>
        </>
      )}
      {promoCodes?.map((promoCode, index) => (
        <Stack key={promoCode.code} direction="row" justify="space-between" fontSize="md">
          <Text>
            {intl.formatMessage({ id: '--promo' })}: {promoCode.code}{' '}
            <FiRsCross fontSize="10px" onClick={promoCode.onRemove} cursor="pointer" />
          </Text>
          <Text>
            <FormattedNumber
              value={promoCode.discount / 100}
              style="currency"
              currency={currencyCode}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </Text>
        </Stack>
      ))}
      <Box py="10px">
        <Stack direction="row" justify="space-between" fontWeight="bold" fontSize="xl">
          <Text>{intl.formatMessage({ id: '--total-price' })}</Text>
          <Text fontFamily="primary">
            <FormattedNumber
              value={totalCost / 100}
              style="currency"
              currency={currencyCode}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </Text>
        </Stack>
        {tax && (
          <>
            {tax
              .filter((e) => e.total > 0)
              .map((tax, index) => (
                <Stack key={index} direction="row" justify="space-between">
                  <Text>
                    {intl.formatMessage({ id: '--total-tax-price' })} ({tax.rate}%)
                  </Text>
                  <Text>
                    <FormattedNumber
                      value={tax.total / 100}
                      style="currency"
                      currency={currencyCode}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Text>
                </Stack>
              ))}
          </>
        )}
        {totalCostIncludingTax && (
          <Stack direction="row" justify="space-between">
            <Text>{intl.formatMessage({ id: '--total-price-including-tax' })}</Text>
            <Text>
              <FormattedNumber
                value={totalCostIncludingTax / 100}
                style="currency"
                currency={currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          </Stack>
        )}
      </Box>
      {buttonProps.disabled && (
        <Text fontSize="sm" mb={1} color="red.500">
          {buttonProps.disabledMessage}
        </Text>
      )}
      {buttonProps.link && (
        <NextLink href={buttonProps.link}>
          <Button
            w="100%"
            colorScheme="accent"
            disabled={buttonProps.disabled}
            onClick={buttonProps.onButtonClick}
            isLoading={buttonProps.isLoading}
            className={buttonProps.className}
          >
            {buttonProps.buttonText}
          </Button>
        </NextLink>
      )}
      {!buttonProps.link && (
        <Button
          w="100%"
          colorScheme="accent"
          disabled={buttonProps.disabled}
          onClick={buttonProps.onButtonClick}
          isLoading={buttonProps.isLoading}
          className={buttonProps.className}
        >
          {buttonProps.buttonText}
        </Button>
      )}
    </Box>
  )
}
