import type { HeadingProps } from '@chakra-ui/react'
import { Box, Button, Container, Heading } from '@chakra-ui/react'
import type { ContentQuery, ElementsFragment } from '@stocker/codegen/pimcore'
import type {
  IDownloadGridProps,
  IEmployeeCardGridProps,
  ILatestNewsProps,
  ITextProps,
  ITextWithImageProps,
} from '@stocker/ui-components/design-system'
import {
  CustomText,
  DownloadGrid,
  EmployeeCardGrid,
  LatestNews,
  TextWithImage,
} from '@stocker/ui-components/design-system'
import {
  asBreadcrumbs,
  asComponent,
  asDownloadText,
  asImageProps,
  asLinkOrWarning,
  asTextOrWarning,
  formatHeadlineColor,
  getDataUrl,
} from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import Link from 'next/link'
import type {
  IFeaturedNewsProps,
  IHeroSwiperProps,
  IProductLineContainerProps,
  ISubscribeNewsletterProps,
} from '../../src'
import { FeaturedNews, HeroSwiper, ProductLineContainer, SubscribeNewsletter } from '../../src'
import Head from 'next/head'

export function prepareContentResponse(contentResponse: ContentQuery) {
  const headerTitleColorOrFallback =
    contentResponse.getContentPageBySlugOrPath?.content?.headerTitleAccentColor ?? 'accent.500'
  const headerColorMap: Record<string, string> = { red: 'accent.500' }
  const headerTitleColor =
    headerTitleColorOrFallback in headerColorMap
      ? headerColorMap[headerTitleColorOrFallback]
      : headerTitleColorOrFallback

  return {
    elements:
      (contentResponse.getContentPageBySlugOrPath?.content?.elements?.filter(
        (element) => element?.__typename,
      ) as ElementsFragment[] | undefined) ?? [],
    header: contentResponse.getContentPageBySlugOrPath?.content?.headerImage?.image
      ? {
          image: asImageProps(contentResponse.getContentPageBySlugOrPath.content.headerImage.image),
          title: contentResponse.getContentPageBySlugOrPath.content.headerTitle ?? '',
          titleColor: headerTitleColor,
        }
      : null,
    seo: {
      title:
        contentResponse.getContentPageBySlugOrPath?.content?.seoTitle ??
        contentResponse.getContentPageBySlugOrPath?.content?.name,
      description: contentResponse.getContentPageBySlugOrPath?.content?.seoDescription ?? '',
      canonicals:
        (
          contentResponse.getContentPageBySlugOrPath?.content?.canonicals?.filter(
            (e) => e?.absolutePath,
          ) as
            | Array<{
                absolutePath: string
              }>
            | undefined
        )?.map((e) => ({ absolutePath: e.absolutePath })) ?? [],
    },
    breadcrumbs: asBreadcrumbs(contentResponse.getContentPageBySlugOrPath?.content),
  }
}

export const getContentBlocks = (res: ContentQuery) => {
  return res.getContentPageBySlugOrPath?.content?.elements?.map((element, index) => {
    if (!element) return

    switch (element.__typename) {
      case 'fieldcollection_HeroSwiper': {
        const props: IHeroSwiperProps = {
          slides:
            element.slides?.map((slide) => ({
              title: asTextOrWarning(slide?.title, 'HeroSwiper.title'),
              text: asComponent(asTextOrWarning(slide?.text, 'HeroSwiper.text')),
              image: asImageProps(slide?.image),
              linkText: asTextOrWarning(slide?.linkText, 'HeroSwiper.linkText'),
              link: {
                href: asLinkOrWarning(
                  slide?.externalLink ? { absolutePath: slide.externalLink } : slide?.href,
                  'HeroSwiper.href',
                ),
                externalLink: slide?.externalLink ?? '',
              },
            })) ?? [],
        }
        return <HeroSwiper {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_Button': {
        return (
          <Box
            w={element.fullWidth ? '100%' : 'auto'}
            maxW={element.fullWidth ? 'unset' : '900px'}
            mx={element.fullWidth ? 'unset' : 'auto'}
            display={element.fullWidth ? 'block' : 'flex'}
            justifyContent={element.fullWidth ? 'unset' : (element.position ?? 'left')}
          >
            <Link href={element.link?.path ?? ''} target={element.isExternal ? '_blank' : ''}>
              <Button
                w={element.fullWidth ? '100%' : 'auto'}
                p={6}
                mt={2}
                fontWeight="light"
                bg={element.color ?? '#444'}
                color="white"
                textTransform="uppercase"
                letterSpacing={2}
                _hover={{ bg: element.color ?? '#444', opacity: 0.6 }}
              >
                {element.link?.text}
              </Button>
            </Link>
          </Box>
        )
      }
      case 'fieldcollection_FeaturedNews': {
        const props: IFeaturedNewsProps = {
          title: asTextOrWarning(element.headline, 'FeaturedNews.title'),
          backgroundImage: asImageProps(element.backgroundImage),
          whiteHeadline: element.whiteHeadline ?? false,
          whiteBar: element.whiteBar ?? false,
          newsCards:
            element.news?.map((news) => ({
              headline: asTextOrWarning(news?.name, 'FeaturedNews.imageCard.headline'),
              text: asComponent(news?.text ?? ''),
              image: asImageProps(news?.productNewsImage ?? news?.image),
              link: { absolutePath: news?.slug ?? '' },
              highlightedFirstWord: '',
            })) ?? [],
        }
        return <FeaturedNews {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_ProductLineContainer': {
        const props: IProductLineContainerProps = {
          title: asTextOrWarning(element.title, 'ProductLineContainer.title'),
          text: asComponent(element.text ?? ''),
          buttonLink: asLinkOrWarning(
            element.externalLink ? { absolutePath: element.externalLink } : element.buttonLink,
            'ProductLineContainer.buttonLink',
          ),
          buttonText: asTextOrWarning(element.buttonText, 'ProductLineContainer.buttonText'),
          productLines:
            element.productLines?.map((productLine, idx) => ({
              image: asImageProps(productLine?.image),
              productLine: productLine?.productLine ?? '',
              title: asTextOrWarning(
                productLine?.title,
                `ProductLineContainer.productLines[${idx}].title`,
              ),
              text: asComponent(productLine?.text ?? ''),
              link: asLinkOrWarning(
                productLine?.externalLink
                  ? { absolutePath: productLine.externalLink }
                  : productLine?.link,
                `ProductLineContainer.productLines[${idx}].link`,
              ),
              linkText: asTextOrWarning(
                productLine?.linkText,
                `ProductLineContainer.productLines[${idx}].linkText`,
              ),
            })) ?? [],
        }
        return <ProductLineContainer {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_EmployeeCardGrid': {
        const props: IEmployeeCardGridProps = {
          headline: asTextOrWarning(element.headline, 'EmployeeCardGrid.headline'),
          people:
            element.employees?.map((person) => ({
              image: asImageProps(person?.image),
              name: asTextOrWarning(person?.name, 'EmployeeCardGrid.name'),
              position: asTextOrWarning(person?.position, 'EmployeeCardGrid.position'),
              telephoneNumber: asTextOrWarning(person?.phone, 'EmployeeCardGrid.telephoneNumber'),
              mobileNumber: person?.mobile ?? undefined,
              email: asTextOrWarning(person?.email, 'EmployeeCardGrid.email'),
            })) ?? [],
        }
        return <EmployeeCardGrid {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_DownloadGrid': {
        const props: IDownloadGridProps = {
          // TODO: get type and size from the backend
          heading: {
            type: 'h2',
            size: { base: '3xl', sm: '4xl' },
            text: asTextOrWarning(element.title, 'DownloadGrid.title'),
          },
          image: asImageProps(element.backgroundImage),
          whiteHeadline: element.whiteHeadline ?? false,
          whiteBar: element.whiteBar ?? false,
          wrappedWithContainer: element.wrappedWithContainer ?? true,
          downloadItems:
            element.downloadItems?.map((downloadItem, idx) => ({
              text: asDownloadText(
                downloadItem?.metadata?.find((e) => e?.name === 'name_download_seite')?.data ??
                  downloadItem?.filename,
                downloadItem?.filesize,
                `DownloadGrid.downloadItems[${idx}]`,
                downloadItem?.filename?.split('.')[1],
              ),
              fileLink: downloadItem?.fullpath ?? '',
              fileName: asTextOrWarning(
                downloadItem?.filename,
                `DownloadGrid.downloadItems[${idx}].text`,
              ),
            })) ?? [],
        }
        return <DownloadGrid {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_NewsCardContainer': {
        const props: ILatestNewsProps = {
          // FIXME: get the totalCount from backend
          totalCount: 0,
        }
        return <LatestNews {...props} />
      }
      case 'fieldcollection_Newsletter': {
        const props: ISubscribeNewsletterProps = {
          heading: {
            text: element.headline ?? '',
            type: 'h2',
          },
          text: element.text ?? '',
          isUnsubscribe: element.formtype === 'unsubscribe',
        }
        return <SubscribeNewsletter {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_Text': {
        const props: ITextProps = {
          text: element.text ?? '',
          isNotFullWidth: element.textBoxed ?? false,
        }
        return <CustomText {...props} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_Script': {
        // inject script body into the body
        if (typeof document !== 'undefined') {
          if (element.scriptSrc) {
            const script = document.createElement('script');
            script.src = element.scriptSrc;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
          }
        }

        return <Box dangerouslySetInnerHTML={{ __html: element.bodyContent ?? '' }} key={`${element.__typename}-${index}`} />
      }
      case 'fieldcollection_Image': {
        return (
          <Container maxW="container.page" key={`${element.__typename}-${index}`}>
            {/* TODO: add width, height and objectFit as params in pimcore so they can be controlled here */}
            <NextImage
              src={element.image?.fullpath ?? ''}
              width="300"
              height="300"
              placeholder={getDataUrl(element.image) ? 'blur' : 'empty'}
              blurDataURL={getDataUrl(element.image)}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
              alt="Collection Image"
            />
          </Container>
        )
      }
      case 'fieldcollection_Headline': {
        const props = {
          headlineType: element.headlineType,
          headline: element.headline,
        }
        return (
          <Heading
            lineHeight={1.2}
            mt={6}
            mb={5}
            pb={4}
            maxW={element.textBoxed ? '900px' : ''}
            mx="auto"
            as={props.headlineType as HeadingProps['as']}
            key={`${element.__typename}-${index}`}
          >
            {formatHeadlineColor(props.headline ?? '')}
          </Heading>
        )
      }
      case 'fieldcollection_TextWithImage': {
        const props: ITextWithImageProps = {
          text: element.text ?? '',
          image: element.image?.fullpath ?? '',
          imageLeft: element.imagePosition === 'left',
        }
        return <TextWithImage {...props} key={`${element.__typename}-${index}`} />
      }
    }
  })
}
