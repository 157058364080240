import { Grid, GridItem, SimpleGridProps } from '@chakra-ui/react'
import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react'
import NextImage from 'next/image'
import type React from 'react'
import type { IDownloadItemProps, IHeading, IImage } from '../../'
import { DownloadItem, Wrap } from '../../'
import { Highline } from '../../../../custom/src/elements/Highline/Highline'

export interface IDownloadGridProps {
  heading?: IHeading
  downloadItems: IDownloadItemProps[]
  wrappedWithContainer?: boolean
  whiteHeadline?: boolean
  whiteBar?: boolean
  image?: IImage
}

interface IColors {
  colorText?: string
}

interface ICombined extends IColors, IDownloadGridProps, SimpleGridProps {}

export const DownloadGrid: React.FC<ICombined> = ({
  downloadItems,
  title,
  colorText = 'secondaryText.500',
  heading,
  columns = 2,
  wrappedWithContainer = true,
  image,
  whiteBar,
  whiteHeadline,
  ...simpleGridProps
}) => {
  // Calc number of items per column
  const itemsPerColumn = Math.ceil(downloadItems.length / 2);

  return (
    <Box position="relative" color={colorText} ml='-20px' mr='-20px'>
      <Box w="100%" h="100%" px="0px" position="absolute" overflow="hidden">
        <Box position="relative" w="100%" h="100%">
          {image?.fullpath && (
            <NextImage
              src={image.fullpath}
              placeholder={image.blurDataUrl ? 'blur' : 'empty'}
              blurDataURL={image.blurDataUrl}
              quality={100}
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
              alt=""
            />
          )}
        </Box>
      </Box>

      <Wrap
        if={wrappedWithContainer}
        with={(children) => (
          <Container maxW="container.content" position={"relative"}>
            {children}
          </Container>
        )}
      >
        <Box zIndex={10} p={{ base: "20px" }}>
          {heading && (
            <Highline colorAccent={whiteBar ? "white" : "accent.500"}>
              <Heading
                as={heading.type}
                mb="24px"
                mt={{ base: "50px", md: "70px" }}
                fontSize={heading.size}
                color={whiteHeadline ? "white" : ""}
              >
                {heading.text}
              </Heading>
            </Highline>
          )}

          {/* FOR ONE COLUMN */}
          { columns === 1 && (
            <SimpleGrid pb="50px" columns={columns} gap={{ base: 0, md: 4 }} spacingY={3} {...simpleGridProps} position={'relative'}>
              {downloadItems.map((downloadItem) => (
                <DownloadItem
                  key={String(downloadItem.text) + String(downloadItem.fileLink)}
                  text={downloadItem.text}
                  fileLink={downloadItem.fileLink}
                  textWhite={whiteHeadline}
                />
              ))}
            </SimpleGrid>
          )}

          {/* FOR TWO COLUMNS */}
          { columns === 2 && (
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              gap={{ base: 0, md: 4 }}
              position={"relative"}
            >
              <GridItem>
                {downloadItems.slice(0, itemsPerColumn).map((downloadItem) => (
                  <DownloadItem
                    key={String(downloadItem.text) + String(downloadItem.fileLink)}
                    text={downloadItem.text}
                    fileLink={downloadItem.fileLink}
                    textWhite={whiteHeadline}
                  />
                ))}
              </GridItem>
              <GridItem>
                {downloadItems.slice(itemsPerColumn).map((downloadItem) => (
                  <DownloadItem
                    key={String(downloadItem.text) + String(downloadItem.fileLink)}
                    text={downloadItem.text}
                    fileLink={downloadItem.fileLink}
                    textWhite={whiteHeadline}
                  />
                ))}
              </GridItem>
            </Grid>
          )}
        </Box>
      </Wrap>
    </Box>
  )
}
