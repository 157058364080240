/* eslint-disable */
import * as Types from './generated-types';

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export const ImageAssetFragmentDoc = gql`
    fragment ImageAsset on asset {
  fullpath(thumbnail: "content")
  data(thumbnail: "base64")
  mimetype
}
    `;
export const DownloadGridFragmentDoc = gql`
    fragment DownloadGrid on fieldcollection_DownloadGrid {
  title
  wrappedWithContainer
  whiteHeadline
  whiteBar
  backgroundImage {
    ...ImageAsset
  }
  downloadItems {
    ... on asset {
      filename
      fullpath
      filesize
      metadata {
        name
        type
        data
        language
      }
    }
  }
}
    `;
export const FeaturedNewsFragmentDoc = gql`
    fragment FeaturedNews on fieldcollection_FeaturedNews {
  headline
  whiteHeadline
  whiteBar
  backgroundImage {
    ...ImageAsset
  }
  news {
    ... on object_NewsPage {
      name
      text
      slug
      image {
        ...ImageAsset
      }
      productNewsImage {
        ...ImageAsset
      }
    }
  }
}
    `;
export const EmployeeCardGridFragmentDoc = gql`
    fragment EmployeeCardGrid on fieldcollection_EmployeeCardGrid {
  headline
  employees {
    ... on object_Staff {
      image {
        fullpath(thumbnail: "content")
        data(thumbnail: "base64")
        mimetype
      }
      name
      position
      phone
      mobile
      email
    }
  }
}
    `;
export const HeroSwiperFragmentDoc = gql`
    fragment HeroSwiper on fieldcollection_HeroSwiper {
  slides {
    title
    text
    image {
      ...ImageAsset
    }
    linkText
    externalLink
    href {
      ... on object_ContentPage {
        absolutePath
      }
    }
  }
}
    `;
export const ProductLineContainerFragmentDoc = gql`
    fragment ProductLineContainer on fieldcollection_ProductLineContainer {
  title
  text
  buttonLink {
    ... on object_ContentPage {
      absolutePath
    }
  }
  buttonText
  externalLink
  productLines {
    image {
      ...ImageAsset
    }
    productLine
    title
    text
    link {
      ... on object_ContentPage {
        absolutePath
      }
    }
    externalLink
    linkText
  }
}
    `;
export const NewsletterFragmentDoc = gql`
    fragment Newsletter on fieldcollection_Newsletter {
  formtype
  headline
  text
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on fieldcollection_Headline {
  headlineType
  headline
  textBoxed
}
    `;
export const TextFragmentDoc = gql`
    fragment Text on fieldcollection_Text {
  text
  textBoxed
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on fieldcollection_Image {
  image {
    fullpath(thumbnail: "content")
    data(thumbnail: "base64")
    mimetype
  }
}
    `;
export const TextWithImageFragmentDoc = gql`
    fragment TextWithImage on fieldcollection_TextWithImage {
  imagePosition
  text
  image {
    ...ImageAsset
  }
}
    `;
export const ButtonFragmentDoc = gql`
    fragment Button on fieldcollection_Button {
  color
  isExternal
  position
  fullWidth
  link {
    text
    path
  }
}
    `;
export const ScriptFragmentDoc = gql`
    fragment Script on fieldcollection_Script {
  scriptSrc
  bodyContent
}
    `;
export const ElementsFragmentDoc = gql`
    fragment elements on object_ContentPage_elements {
  __typename
  ...DownloadGrid
  ...FeaturedNews
  ...EmployeeCardGrid
  ...HeroSwiper
  ...ProductLineContainer
  ...Newsletter
  ...Headline
  ...Text
  ...Image
  ...TextWithImage
  ...Button
  ...Script
}
    `;
export const HotspotImageFragmentDoc = gql`
    fragment HotspotImage on hotspotimage {
  image {
    mobileImage: fullpath(thumbnail: "content")
    fullpath(thumbnail: "300dpi-full-width")
    data(thumbnail: "base64")
    mimetype
  }
}
    `;
 const ContentDocument = gql`
    query Content($path: String!, $lang: String!) {
  getContentPageBySlugOrPath(absolutePath: $path, language: $lang) {
    content {
      name
      absolutePath
      parent {
        ... on object_ContentPage {
          name
          absolutePath
          parent {
            ... on object_ContentPage {
              name
              absolutePath
              parent {
                ... on object_ContentPage {
                  name
                  absolutePath
                  parent {
                    ... on object_ContentPage {
                      name
                      absolutePath
                    }
                  }
                }
              }
            }
          }
        }
      }
      seoTitle
      seoDescription
      headerTitle
      headerTitleAccentColor
      headerImage {
        image {
          ...ImageAsset
        }
      }
      canonicals {
        ... on object_ContentPage {
          absolutePath
        }
      }
      elements {
        ...elements
      }
    }
  }
}
    ${ImageAssetFragmentDoc}
${ElementsFragmentDoc}
${DownloadGridFragmentDoc}
${FeaturedNewsFragmentDoc}
${EmployeeCardGridFragmentDoc}
${HeroSwiperFragmentDoc}
${ProductLineContainerFragmentDoc}
${NewsletterFragmentDoc}
${HeadlineFragmentDoc}
${TextFragmentDoc}
${ImageFragmentDoc}
${TextWithImageFragmentDoc}
${ButtonFragmentDoc}
${ScriptFragmentDoc}`;
 const ContentPagesDocument = gql`
    query ContentPages($defaultLanguage: String!) {
  getContentPageListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        absolutePath
      }
    }
  }
}
    `;
 const ClassificationSortingDocument = gql`
    query ClassificationSorting($defaultLanguage: String) {
  getWebsiteConfigurationListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        sortingClassification {
          ... on object_ProductClassification {
            name
          }
        }
      }
    }
  }
}
    `;
 const StaffListingDocument = gql`
    query StaffListing($shortCodeFilter: String!) {
  getStaffListing(filter: $shortCodeFilter, first: 1) {
    edges {
      node {
        image {
          fullpath
        }
        shortCode
        name
        position
        phone
        mobile
        email
      }
    }
  }
}
    `;
 const SystemFinderListingDocument = gql`
    query SystemFinderListing {
  getSystemFinderListing {
    edges {
      node {
        introText
        introHeadline
        introImage {
          fullpath(thumbnail: "content")
        }
        projectHeadline
        projectText
        project {
          projectFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        operatingModeHeadline
        operatingModeText
        operatingMode {
          operatingModeFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        doubleWalledPressureHeadline
        doubleWalledPressureText
        doubleWalledPressure {
          doubleWalledPressureFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        pressureHeadline
        pressureText
        pressure {
          pressureFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        roomAirHeadline
        roomAirText
        roomAir {
          roomAirFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
        executionHeadline
        executionText
        execution {
          executionFilter {
            ... on object_SystemFinderFilter {
              id
            }
          }
          headline
          text
          image {
            fullpath(thumbnail: "content")
          }
        }
      }
    }
  }
}
    `;
 const NavigationByIdentifierDocument = gql`
    query NavigationByIdentifier($identifier: String!, $lang: String!) {
  navigationByIdentifier(identifier: $identifier, language: $lang) {
    linkItems {
      title
      absolutePath
      additionalData
      nameInNavigation
      slug
      className
      linkItems {
        title
        slug
        absolutePath
        additionalData
        nameInNavigation
        className
        linkItems {
          title
          additionalData
          nameInNavigation
          slug
          absolutePath
          className
          linkItems {
            title
            additionalData
            nameInNavigation
            slug
            absolutePath
            className
          }
        }
      }
    }
  }
}
    `;
 const NewsDocument = gql`
    query News($path: String!, $lang: String!) {
  getNewsItemBySlugOrPath(absolutePath: $path, language: $lang) {
    newsItem {
      seoTitle
      seoDescription
      headerTitle
      headerTitleAccentColor
      headerImage {
        ...HotspotImage
      }
      canonicals {
        ... on object_ContentPage {
          absolutePath
        }
      }
      elements {
        __typename
        ...Text
        ...Headline
        ...Image
        ...TextWithImage
      }
      name
    }
  }
}
    ${HotspotImageFragmentDoc}
${TextFragmentDoc}
${HeadlineFragmentDoc}
${ImageFragmentDoc}
${TextWithImageFragmentDoc}
${ImageAssetFragmentDoc}`;
 const LatestNewsDocument = gql`
    query LatestNews($first: Int = 5, $after: Int = 0, $lang: String!) {
  getNewsPageListing(
    sortBy: "displayDate"
    sortOrder: "DESC"
    first: $first
    after: $after
    defaultLanguage: $lang
  ) {
    totalCount
    edges {
      node {
        displayDate
        absolutePath
        name
        text
        image {
          ...ImageAsset
        }
      }
    }
  }
}
    ${ImageAssetFragmentDoc}`;
 const NewsPagesDocument = gql`
    query NewsPages($defaultLanguage: String!) {
  getNewsPageListing(defaultLanguage: $defaultLanguage) {
    edges {
      node {
        absolutePath
      }
    }
  }
}
    `;
 const ProductCategoryListingDocument = gql`
    query ProductCategoryListing($filter: String!) {
  getProductCategoryListing(filter: $filter, defaultLanguage: "de", first: 1) {
    edges {
      node {
        subheadline
        coloredName
        description
        infoImage {
          fullpath(thumbnail: "content")
          filename
          dimensions {
            width
            height
          }
        }
        onSiteImages {
          image {
            filename
            fullpath(thumbnail: "content")
          }
        }
        systemInformationItems {
          itemText
          itemHeadline
        }
        relatedProduct {
          ... on object_Product {
            slug
          }
        }
        downloads {
          ... on asset {
            filename
            fullpath
            metadata {
              name
              data
              language
            }
          }
        }
      }
    }
  }
}
    `;
 const ProductCategoryListingSystemFinderDocument = gql`
    query ProductCategoryListingSystemFinder($filter: String!) {
  getProductCategoryListing(filter: $filter, defaultLanguage: "de") {
    edges {
      node {
        isSystem
        slug
        description
        text
        coloredName
        id
        infoImage {
          fullpath(thumbnail: "content")
        }
        relatedProduct {
          ... on object_Product {
            slug
          }
        }
        diameter
        wallThickness
        material
        maxExhaustTemperature
        systemFinderFilters {
          __typename
          ... on object_SystemFinderFilter {
            id
          }
        }
      }
    }
  }
}
    `;
 const SiteConfigListingDocument = gql`
    query SiteConfigListing($language: String!) {
  getSiteConfigListing(defaultLanguage: $language) {
    edges {
      node {
        customerDownloads {
          __typename
          ... on asset {
            id
            filename
            fullpath
            type
            filesize
            metadata {
              name
              data
              language
            }
          }
        }
      }
    }
  }
}
    `;
 const WebsiteConfigurationDocument = gql`
    query WebsiteConfiguration($id: Int, $language: String) {
  getWebsiteConfiguration(id: $id, defaultLanguage: $language) {
    newsletter
    links {
      headline
      links {
        ... on object_ContentPage {
          name
          absolutePath
        }
      }
    }
    contact
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Content(variables: Types.ContentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ContentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ContentQuery>(ContentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Content', 'query', variables);
    },
    ContentPages(variables: Types.ContentPagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ContentPagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ContentPagesQuery>(ContentPagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContentPages', 'query', variables);
    },
    ClassificationSorting(variables?: Types.ClassificationSortingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ClassificationSortingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ClassificationSortingQuery>(ClassificationSortingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ClassificationSorting', 'query', variables);
    },
    StaffListing(variables: Types.StaffListingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.StaffListingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.StaffListingQuery>(StaffListingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StaffListing', 'query', variables);
    },
    SystemFinderListing(variables?: Types.SystemFinderListingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SystemFinderListingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SystemFinderListingQuery>(SystemFinderListingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SystemFinderListing', 'query', variables);
    },
    NavigationByIdentifier(variables: Types.NavigationByIdentifierQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.NavigationByIdentifierQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.NavigationByIdentifierQuery>(NavigationByIdentifierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NavigationByIdentifier', 'query', variables);
    },
    News(variables: Types.NewsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.NewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.NewsQuery>(NewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'News', 'query', variables);
    },
    LatestNews(variables: Types.LatestNewsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.LatestNewsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.LatestNewsQuery>(LatestNewsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LatestNews', 'query', variables);
    },
    NewsPages(variables: Types.NewsPagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.NewsPagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.NewsPagesQuery>(NewsPagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NewsPages', 'query', variables);
    },
    ProductCategoryListing(variables: Types.ProductCategoryListingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ProductCategoryListingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ProductCategoryListingQuery>(ProductCategoryListingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProductCategoryListing', 'query', variables);
    },
    ProductCategoryListingSystemFinder(variables: Types.ProductCategoryListingSystemFinderQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ProductCategoryListingSystemFinderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ProductCategoryListingSystemFinderQuery>(ProductCategoryListingSystemFinderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProductCategoryListingSystemFinder', 'query', variables);
    },
    SiteConfigListing(variables: Types.SiteConfigListingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SiteConfigListingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SiteConfigListingQuery>(SiteConfigListingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SiteConfigListing', 'query', variables);
    },
    WebsiteConfiguration(variables?: Types.WebsiteConfigurationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.WebsiteConfigurationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.WebsiteConfigurationQuery>(WebsiteConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WebsiteConfiguration', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;