import type { SimpleGridProps } from '@chakra-ui/react'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import NextImage from 'next/image'
import type React from 'react'
import { useState } from 'react'
import type { IImage } from '../../'
export interface IImageGallery extends SimpleGridProps {
  images: IImage[]
}

export const ImageGallery: React.FC<IImageGallery> = ({
  images,
  columns = { base: 1, md: 2 },
  ...simpleGridProps
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [image, setImage] = useState<IImage>()

  return (
    <SimpleGrid columns={columns} justifyItems="center" spacing="15px" {...simpleGridProps}>
      {images.map((image, idx) => (
        <Box
          key={idx}
          onClick={() => {
            setImage(image)
            onToggle()
          }}
          cursor="pointer"
          position="relative"
          w="100%"
          _before={{
            content: '""',
            paddingBottom: '100%',
            display: 'block',
          }}
        >
          <NextImage
            src={image.fullpath ?? '/'}
            placeholder={image.blurDataUrl ? 'blur' : 'empty'}
            blurDataURL={image.blurDataUrl}
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
            alt="Image Gallery Item"
          />
        </Box>
      ))}
      <Modal isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay>
          <ModalCloseButton color="white" cursor="pointer" />
        </ModalOverlay>
        <ModalContent bgColor="transparent" onClick={onClose} p={6}>
          <ModalBody>
            <NextImage
              src={image?.fullpath ?? '/'}
              placeholder={image?.blurDataUrl ? 'blur' : 'empty'}
              blurDataURL={image?.blurDataUrl}
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
              }}
              alt="Image Gallery Item"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </SimpleGrid>
  )
}
